<template>
  <div class="app">
    <Layout>
      <Content class="upload_content">
        <sellerSider></sellerSider>
        <Content class="upload_content_body">
          <Layout>
            <Header>
              <div class="header-words">
                <h1 class="upload-header-words">
                  内容管理
                </h1>
              </div>
              <div class="manage-type-title">
                <span class="manage-type-words">状态</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('all',$event)">全部视频</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('new',$event)">已发布</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('audit',$event)">审核中</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('audit-failure',$event)">审核失败</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('release',$event)">已通过</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('unsettle',$event)">未结算</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('settled',$event)">已结算</span>
                <span class="manage-type-words clickable-type" @click="getVideoListByType('settle-failure',$event)">结算失败</span>
                <span class="manage-type-words" style="float: right;transform:translateY(100%);">共&nbsp;{{ totalVideo }}&nbsp;个视频</span>
              </div>
            </Header>
            <Content class="upload-body">
              <Table :height="tableHeight" size="large" :columns="videoColumns" :data="uploadList" ></Table>
              <Page id="videoPage" class="videoPage" :total="totalVideo" show-sizer show-total show-elevator :page-size-opts="[5]"
                    @on-change="changePage" :page-size="pageSize" @on-page-size-change="changePageSize"/>
            </Content>
          </Layout>
        </Content>
      </Content>
    </Layout>
  </div>
</template>

<script>
  import sellerSider from "../../../components/sellerSider"

  export default {
    name: "video-manage",
    components: {
      sellerSider,
    },
    data() {
      return{
        totalVideo:0,
        tableHeight:null,
        videoColumns:[
          {
            title: '视频预览',
            key: 'preview_frame_url',
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h('img', {
                  style: {
                    width: "80px",
                    verticalAlign: "middle",
                    maxHeight: "120px",
                    cursor:"pointer"
                  },
                  attrs: {
                    src: params.row.preview_frame_url
                  },
                  on: {
                    click:() => {
                      this.playVideo(params.row.url);
                    }
                  }
                },)
              ]);
            }
          },
          {
            title: '视频标题',
            key: 'title',
            align: 'center',
          },
          {
            title: '视频描述',
            key: 'description',
            align: 'center',
          },
          {
            title: '视频状态',
            key: 'status',
            align: 'center',
          },
          {
            title: '结算状态',
            key: 'discount_status',
            align: 'center',
          },
          {
            title: '视频时长',
            key: 'duration',
            align: 'center',
          },
          {
            title: '上传时间',
            key: 'upload_time',
            align: 'center',
          },
        ],
        uploadList:[],
        pageNum:1,
        pageSize:5,
        type:"all",
      }
    },
    mounted() {
      this.getVideoList(1,5,"all");
      let domArr = Array.from(this.$el.getElementsByClassName("clickable-type"));
      domArr[0].style.color = "#0ACCF7";
    },
    created() {

    },
    methods: {
      getVideoList(pageNum,pageSize,type){
        let token = this.common.getAuth();
        let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
        let obj = {"pageNumber":pageNum,"pageSize":pageSize,"upload_id":userInfo.id,"type":type};
        this.$axios.post(`${this.apiUrlCros}/api/api/video/listApi`,obj,{
          "headers" : {
            "Eova-Authorization" : token
          }
        }).then(res => {
          if (res.data.code == "200") {
            this.uploadList = res.data.resData.list;
            this.totalVideo = res.data.resData.totalRow;
            this.pageSize = res.data.resData.pageSize;
          } else {
            this.$Message.error(res.data.message);
            if (res.data.code === "6000") {
              this.$router.push({name:"login"});
            }
          }
        }).catch(err => {
          this.$Message.error(err);
        })
      },
      changePage(index) {
        this.pageNum = index;
        this.getVideoList(this.pageNum,this.pageSize,this.type);
      },
      changePageSize(size) {
        this.pageSize = size;
        this.getVideoList(this.pageNum,this.pageSize,this.type);
      },
      getVideoListByType(type,e) {
        this.type = type;
        this.getVideoList(this.pageNum,this.pageSize,this.type);
        let clicks = Array.from(this.$el.getElementsByClassName("clickable-type"));
        clicks.forEach(element=> element.style.color= "#161823");
        e.currentTarget.style.color = "#0ACCF7";
      },
      playVideo(url) {
        this.$Modal.confirm({
          width:"1100",
          render: (h) => {
            return h('video', {
              props: {
                width: "200"
              },
              style: {
                display:"flex",
                margin:"auto"
              },
              attrs: {
                src: url,
                autoplay:true,
                loop:true
              },
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .manage-type-title {
    padding: 20px 0;
    background-color: #ffffff;
    z-index: 990;
    margin-bottom: 8px;
    border-bottom: 1px rgba(51, 51, 51, 0.1) solid;

    .manage-type-words {
      font-size: 16px;
      line-height: 22px;
      color: rgba(22, 24, 35, 0.34);
      margin-right: 20px;
    }

    .clickable-type {
      margin-right: 20px;
      cursor: pointer;
      font-size: 16px;
      line-height: 22px;
      color: #161823;
    }
  }

  .upload-body {
    margin-top: 90px;
    background: #fff;

    .videoPage {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 45%;
      transform: translateY(-200%);
    }
  }

  @media only screen and (min-width: 2560px) {
    .upload_content_body {
      min-height: 1080px;
    }
  }

</style>
